/**
 * ApiMetadata API
 * Version: 1.0
 * Build: 1.0.0.53
 */

import {
	ListInfo,
	AuditInfo,
	ActionCd,
	ApiAuthTypeCd,
	ApiContractExceptionSeverityCd,
	ApiContractImplApiMethodCd,
	ApiContractImplProtocolCd,
	ApiContractImplTechnology,
	ApiContractStatusCd,
	ApiContractTypeCategoryCd,
	ApiFeatureStatusCd,
	ApiThrottlingTierCd,
	ApiTypeAttributeDataTypeCd,
	ApiTypeCategoryCd,
	ApiTypeStatusCd,
	ExadataDatabaseCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to create a new Component
 */
export class CreateComponentRqst {
	/**
	 * Component details
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
}
/**
 * Response to the request to create a new Component
 */
export class CreateComponentResp {
	/**
	 * Component details
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
}
/**
 * Request to create a new Contract
 */
export class CreateContractRqst {
	/**
	 * Contract header and child types
	 * Required: false
	 * Array: false
	 */
	contract:Contract = undefined;
}
/**
 * Response to the request to create a new Contract
 */
export class CreateContractResp {
	/**
	 * Contract header and child types
	 * Required: false
	 * Array: false
	 */
	contract:Contract = undefined;
}
/**
 * Request to create a new Custom Type
 */
export class CreateCustomTypeRqst {
	/**
	 * Type details
	 * Required: false
	 * Array: false
	 */
	type:Type = undefined;
}
/**
 * Response to the request to create a new Custom Type
 */
export class CreateCustomTypeResp {
	/**
	 * Type details
	 * Required: false
	 * Array: false
	 */
	type:Type = undefined;
}
/**
 * Request to create a new Feature
 */
export class CreateFeatureRqst {
	/**
	 * Feature details
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Response to the request to create a new Feature
 */
export class CreateFeatureResp {
	/**
	 * Feature details
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Request to generate all arsenal artifacts for a component
 */
export class CreateFullFileComponentRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * Delete the component identified by the input component cd
 */
export class DeleteComponentPath {
	/**
	 * The code for the API component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * Delete the contract identified by the input component cd, feature id and service name
 */
export class DeleteContractPath {
	/**
	 * The code for the API component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * Identifier of the feature that contains the contract
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * Name of the service that identifies the contract
	 * Required: true
	 * Array: false
	 */
	serviceName:string = undefined;
}
/**
 * Delete the custom type identified by the input component cd, feature id and type name
 */
export class DeleteCustomTypePath {
	/**
	 * The code for the API component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * Identifier of the feature that contains the custom type
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * Name that idetnifies the custom type
	 * Required: true
	 * Array: false
	 */
	typeName:string = undefined;
}
/**
 * Delete the feature identified by the input feature id
 */
export class DeleteFeaturePath {
	/**
	 * Identifier for the feature
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
}
/**
 * Retrieve the component identified by the input component cd
 */
export class GetComponentPath {
	/**
	 * The code for the API component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * Enter comments
 */
export class GetComponentResp {
	/**
	 * The API component that was requested by code.
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
	/**
	 * The parent business component for the API component
	 * Required: false
	 * Array: false
	 */
	superComponent:SuperComponent = undefined;
}
/**
 * Retrieve a feature by feature id
 */
export class GetContractPath {
	/**
	 * The code for the API component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * Identifier of the feature that contains the contract
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * Name of the service that idetnifies the contract
	 * Required: true
	 * Array: false
	 */
	serviceName:string = undefined;
}
/**
 * Return a contract given the contract identifiers
 */
export class GetContractResp {
	/**
	 * The contract identified by the input path parameters
	 * Required: false
	 * Array: false
	 */
	contract:Contract = undefined;
}
/**
 * Retrieve a feature by feature id
 */
export class GetFeatureByIdPath {
	/**
	 * The id for the feature
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
}
/**
 * Return a feature given feature id
 */
export class GetFeatureByIdResp {
	/**
	 * The feature for the given feature id
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Retrieve a type by feature id and type name
 */
export class GetTypeByFeatureIdAndNamePath {
	/**
	 * The id for the feature
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * The name of the type to retrieve
	 * Required: true
	 * Array: false
	 */
	typeName:string = undefined;
}
/**
 * Return a type given feature id and type name
 */
export class GetTypeByFeatureIdAndNameResp {
	/**
	 * The feature for the given feature id
	 * Required: false
	 * Array: false
	 */
	type:Type = undefined;
}
/**
 * List the components based on input criteria
 */
export class ListComponentsQuery {
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Enter comments
 */
export class ListComponentsResp {
	/**
	 * A list of API components.
	 * Required: false
	 * Array: true
	 */
	components:Component[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the contracts based on input component cd
 */
export class ListContractsByComponentCdPath {
	/**
	 * Component cd identifier.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * List the contracts based on input component cd
 */
export class ListContractsByComponentCdQuery {
	/**
	 * Status cd for the type
	 * Required: false
	 * Array: true
	 */
	statusCd:ApiContractStatusCd[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the contracts based on input component cd
 */
export class ListContractsByComponentCdResp {
	/**
	 * List of contracts for a component cd.
	 * Required: false
	 * Array: true
	 */
	contracts:Contract[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the contracts based on input feature id
 */
export class ListContractsByFeatureIdPath {
	/**
	 * Feature id.
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
}
/**
 * List the contracts based on input feature id
 */
export class ListContractsByFeatureIdQuery {
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the contracts based on input feature id
 */
export class ListContractsByFeatureIdResp {
	/**
	 * List of contracts for a feature id
	 * Required: false
	 * Array: true
	 */
	contracts:Contract[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List all Enums that exist
 */
export class ListEnumsQuery {
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List all enums
 */
export class ListEnumsResp {
	/**
	 * List of enums
	 * Required: false
	 * Array: true
	 */
	enums:EnumType[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the features based on input component cd
 */
export class ListFeaturesByComponentCdPath {
	/**
	 * Component cd identifier.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * List the features based on input component cd
 */
export class ListFeaturesByComponentCdQuery {
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the features based on input component cd
 */
export class ListFeaturesByComponentCdResp {
	/**
	 * List of features for a component cd.
	 * Required: false
	 * Array: true
	 */
	features:Feature[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the types based on input component cd
 */
export class ListTypesByComponentCdPath {
	/**
	 * Component cd identifier.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * List the types based on input component cd
 */
export class ListTypesByComponentCdQuery {
	/**
	 * Category cd for the type
	 * Required: false
	 * Array: false
	 */
	categoryCd:ApiTypeCategoryCd = undefined;
	/**
	 * Status cd for the type
	 * Required: false
	 * Array: true
	 */
	statusCd:ApiTypeStatusCd[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Feature id.
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
}
/**
 * List the types based on input component cd
 */
export class ListTypesByComponentCdResp {
	/**
	 * List of types for a component cd.
	 * Required: false
	 * Array: true
	 */
	types:Type[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the types based on input feature id
 */
export class ListTypesByFeatureIdPath {
	/**
	 * Feature id.
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
}
/**
 * List the types based on input feature id
 */
export class ListTypesByFeatureIdQuery {
	/**
	 * Category cd for the type
	 * Required: false
	 * Array: false
	 */
	categoryCd:ApiTypeCategoryCd = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List the types based on input feature id
 */
export class ListTypesByFeatureIdResp {
	/**
	 * List of types for a feature id
	 * Required: false
	 * Array: true
	 */
	types:Type[] = undefined;
	/**
	 * List criteria.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to create a new Feature
 */
export class SubmitFeatureRqst {
	/**
	 * Feature details
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * This operation updates the status of the submitted Feature and any related Types or Contracts back to Saved status.  The featureId is required as input.
 */
export class RevertSubmittedFeatureRqst {
	/**
	 * The id for the feature
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
}
/**
 * Return the reverted feature.
 */
export class RevertSubmittedFeatureResp {
	/**
	 * The updated API Feature.
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Response to the request to create a new Feature
 */
export class SubmitFeatureResp {
	/**
	 * Feature details
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Update the component based on the input comonent record
 */
export class UpdateComponentRqst {
	/**
	 * The payload that will be used to update the API component.
	 * Required: true
	 * Array: false
	 */
	component:Component = undefined;
}
/**
 * Response to the request to update a Component
 */
export class UpdateComponentResp {
	/**
	 * Component that was updated
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
}
/**
 * Request to update a Contract
 */
export class UpdateContractRqst {
	/**
	 * Contract header and child types
	 * Required: false
	 * Array: false
	 */
	contract:Contract = undefined;
}
/**
 * Response to the request to update a Contract
 */
export class UpdateContractResp {
	/**
	 * Contract header and child types
	 * Required: false
	 * Array: false
	 */
	contract:Contract = undefined;
}
/**
 * Request to update a Custom Type
 */
export class UpdateCustomTypeRqst {
	/**
	 * Type details
	 * Required: false
	 * Array: false
	 */
	type:Type = undefined;
}
/**
 * Response to the request to update a Custom Type
 */
export class UpdateCustomTypeResp {
	/**
	 * Type details
	 * Required: false
	 * Array: false
	 */
	type:Type = undefined;
}
/**
 * Request to update a Feature
 */
export class UpdateFeatureRqst {
	/**
	 * Feature details
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Response to the request to update a Feature
 */
export class UpdateFeatureResp {
	/**
	 * Feature details
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Request to mark a Feature as Implemented
 */
export class UpdateFeatureAsImplementedRqst {
	/**
	 * The unique identifier for the API Feature.
	 * Required: true
	 * Array: false
	 */
	featureId:number = undefined;
}
/**
 * Response to the request to mark a Feature as Implemented
 */
export class UpdateFeatureAsImplementedResp {
	/**
	 * The updated API Feature.
	 * Required: false
	 * Array: false
	 */
	feature:Feature = undefined;
}
/**
 * Path parameter to list the Exadata user information.
 */
export class ListExadataUserByEmailAddressPath {
}
/**
 * Response to the request to list the Exadata user information.
 */
export class ListExadataUserByEmailAddressResp {
	/**
	 * The list of Exadata user information.
	 * Required: false
	 * Array: true
	 */
	exadataUser:ExadataUser[] = undefined;
}
/**
 * Path parameter to update the Exadata user information.
 */
export class UpdateExadataUserPath {
	/**
	 * The database name where the user exists.
	 * Required: true
	 * Array: false
	 */
	databaseName:ExadataDatabaseCd = undefined;
}
/**
 * Request to update the Exadata user information.
 */
export class UpdateExadataUserRqst {
	/**
	 * The Exadata user information to be updated.
	 * Required: false
	 * Array: true
	 */
	exadataUser:ExadataUser[] = undefined;
}
/**
 * Response to the request to update the Exadata user information.
 */
export class UpdateExadataUserResp {
	/**
	 * The Exadata user information that got updated.
	 * Required: false
	 * Array: true
	 */
	exadataUser:ExadataUser[] = undefined;
}
/**
 * Exadata user information.
 */
export class ExadataUser {
	/**
	 * The status of the user in the database.
	 * Required: false
	 * Array: false
	 */
	status:string = undefined;
	/**
	 * The database where the user is given access.
	 * Required: false
	 * Array: false
	 */
	databaseCd:ExadataDatabaseCd = undefined;
	/**
	 * The user name.
	 * Required: false
	 * Array: false
	 */
	userName:string = undefined;
	/**
	 * The user password.
	 * Required: false
	 * Array: false
	 */
	password:string = undefined;
	/**
	 * The date of when the user was locked out.
	 * Required: false
	 * Array: false
	 */
	lockoutDate:Date = undefined;
	/**
	 * The expiry date of this user.
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * The creation date of this user.
	 * Required: false
	 * Array: false
	 */
	createDate:Date = undefined;
	/**
	 * The last logged on date for this user.
	 * Required: false
	 * Array: false
	 */
	lastLogonDate:Date = undefined;
	/**
	 * The email address of the user.
	 * Required: false
	 * Array: false
	 */
	emailAddress:string = undefined;
}
/**
 * 
 */
export class AemComponent {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	aemComponentCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class Component {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentDescription:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contextUrl:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contractRepoName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	versionNbrTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	superComponent:SuperComponent = undefined;
}
/**
 * 
 */
export class ComponentTable {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentTblName:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class Contract {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:ApiContractStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessUnit:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceOwner:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceVersionNbrTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceShortDescr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceDescription:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	contractException:ContractException[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	contractImplementation:ContractImplementation[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	contractTag:ContractTag[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	contractType:ContractType[] = undefined;
}
/**
 * 
 */
export class ContractException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faultCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faultMessage:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faultDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	severityCd:ApiContractExceptionSeverityCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class ContractImplementation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	seqNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	protocol:ApiContractImplProtocolCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	uriPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apiMethod:ApiContractImplApiMethodCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	implTechnology:ApiContractImplTechnology = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * The authentication type for the contract implementation.  
	 * 
	 * Valid values:
	 * None
	 * Application
	 * Application User
	 * Application and Application User (default)
	 * 
	 * Added 03/10/2020 by Dan Jimenez
	 * Required: false
	 * Array: false
	 */
	authTypeCd:ApiAuthTypeCd = undefined;
	/**
	 * The name associated with the group of roles that may have access to a specific API operation.
	 * 
	 * Added 03/10/2020 by Dan Jimenez
	 * Required: false
	 * Array: false
	 */
	scopeName:string = undefined;
	/**
	 * The setting that should be applied to the API for the number of requests allowed within a unit of time.  
	 * 
	 * Valid values: 
	 * Unlimited (default)
	 * 50KPerMin
	 * 20KPerMin
	 * 10KPerMin
	 * 
	 * Added 03/10/2020 by Dan Jimenez
	 * Required: false
	 * Array: false
	 */
	throttlingTierCd:ApiThrottlingTierCd = undefined;
}
/**
 * 
 */
export class ContractTag {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	seqNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tagName:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class ContractType {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contractCategoryCd:ApiContractTypeCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contractTypeName:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	contractTypeAttribute:ContractTypeAttribute[] = undefined;
}
/**
 * 
 */
export class ContractTypeAttribute {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:ApiTypeCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cntrctTypAttrName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	minimumNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	maximumNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dataType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contractTypeAttrSeqNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contractCategoryCd:ApiContractTypeCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enumType:EnumType = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentType:Type = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentTypeAttribute:TypeAttribute = undefined;
}
/**
 * 
 */
export class DbColumn {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tableName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	columnName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	seqNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	columnTypeTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dataLength:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dataPrecision:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dataScale:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fkColumnInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pkColumnInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nullableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	manuallyAddedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	excludeFromSchemaInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unboundedInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class DbRelationship {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	relationshipName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cardinalityTxt:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentTableName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	childTableName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class DbTable {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tableName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tablespaceName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	historyTableName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	excludeFromSchemaInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	dbColumn:DbColumn[] = undefined;
}
/**
 * 
 */
export class EnumType {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enumNameTxt:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	enumValue:EnumValue[] = undefined;
}
/**
 * 
 */
export class EnumValue {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enumCodeTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enumLabel:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enumValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enumNameTxt:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class Feature {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:ApiFeatureStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	jiraTicketNumber:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	gitPullRequest:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Employee details for the create by employee id.
	 * Required: false
	 * Array: false
	 */
	createByEmployeeDtl:InterfaceEmployee = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	contract:Contract[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	type:Type[] = undefined;
}
/**
 * Contains the details of who/what is subscribed 
 * 
 * to the application
 */
export class Subscription {
	/**
	 * Unique id for the 
	 * 
	 * Subscription
	 * Required: false
	 * Array: false
	 */
	subscriptionInstId:number = undefined;
	/**
	 * 'Foreign key' to the application 
	 * 
	 * (AMD_APPLICATION)
	 * Required: false
	 * Array: false
	 */
	applInstId:number = undefined;
	/**
	 * Name of the user used for 
	 * 
	 * subscribing the API
	 * Required: false
	 * Array: false
	 */
	userName:string = undefined;
	/**
	 * Name of the application for 
	 * 
	 * subscription
	 * Required: false
	 * Array: false
	 */
	subscriptionName:string = undefined;
	/**
	 * Access key for the API 
	 * 
	 * subscription
	 * Required: false
	 * Array: false
	 */
	apiAccessKey:string = undefined;
	/**
	 * SSO secret key for the API 
	 * 
	 * subscription
	 * Required: false
	 * Array: false
	 */
	authSecretKey:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Contains further details of the 
 * 
 * Subscription
 */
export class SubscriptionDetail {
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentName:string = undefined;
	/**
	 * Unique id for the 
	 * 
	 * Subscription
	 * Required: false
	 * Array: false
	 */
	subscriptionInstId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class SuperComponent {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentDescription:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
}
/**
 * 
 */
export class Type {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:ApiTypeCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:ApiTypeStatusCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbTable:DbTable = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	typeAttribute:TypeAttribute[] = undefined;
}
/**
 * 
 */
export class TypeAttribute {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	attributeName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	minimumNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	maximumNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dataType:ApiTypeAttributeDataTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	formattingRule:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	attributeSeqNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:ApiTypeCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbColumn:DbColumn = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enumType:EnumType = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentTypeAttribute:TypeAttribute = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentType:Type = undefined;
}
/**
 * Details about employees that process and approve claims.
 */
export class InterfaceEmployee {
	/**
	 * Employee Id of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First Name of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Middle Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * Last Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Job Description of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The domicile SIC location for the employee.
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * The employee id of this employee's supervisor.
	 * Required: false
	 * Array: false
	 */
	supervisorEmployeeId:string = undefined;
	/**
	 * Desc: The business phone number of the employee.  It is sourced from HRS_EMPL_PHONE_VW in DB2 for typ_cd = 'B'.  01/10/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	businessPhoneTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	racfId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrCellPhone:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}



