import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CreateComponentRqst,
	CreateComponentResp,
	CreateContractRqst,
	CreateContractResp,
	CreateCustomTypeRqst,
	CreateCustomTypeResp,
	CreateFeatureRqst,
	CreateFeatureResp,
	CreateFullFileComponentRqst,
	DeleteComponentPath,
	DeleteContractPath,
	DeleteCustomTypePath,
	DeleteFeaturePath,
	GetComponentResp,
	GetComponentPath,
	GetContractResp,
	GetContractPath,
	GetFeatureByIdResp,
	GetFeatureByIdPath,
	GetTypeByFeatureIdAndNameResp,
	GetTypeByFeatureIdAndNamePath,
	ListComponentsResp,
	ListComponentsQuery,
	ListContractsByComponentCdResp,
	ListContractsByComponentCdQuery,
	ListContractsByComponentCdPath,
	ListContractsByFeatureIdResp,
	ListContractsByFeatureIdQuery,
	ListContractsByFeatureIdPath,
	ListEnumsResp,
	ListEnumsQuery,
	ListExadataUserByEmailAddressResp,
	ListFeaturesByComponentCdResp,
	ListFeaturesByComponentCdQuery,
	ListFeaturesByComponentCdPath,
	ListTypesByComponentCdResp,
	ListTypesByComponentCdQuery,
	ListTypesByComponentCdPath,
	ListTypesByFeatureIdResp,
	ListTypesByFeatureIdQuery,
	ListTypesByFeatureIdPath,
	RevertSubmittedFeatureRqst,
	RevertSubmittedFeatureResp,
	SubmitFeatureRqst,
	SubmitFeatureResp,
	UpdateComponentRqst,
	UpdateComponentResp,
	UpdateContractRqst,
	UpdateContractResp,
	UpdateCustomTypeRqst,
	UpdateCustomTypeResp,
	UpdateFeatureRqst,
	UpdateFeatureResp,
	UpdateFeatureAsImplementedRqst,
	UpdateFeatureAsImplementedResp,
	UpdateExadataUserRqst,
	UpdateExadataUserResp,
	UpdateExadataUserPath
} from './api-apimetadata';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ApiMetadataApiService extends BaseService {
	private static ApiMetadataApiEndpoint = 'apimetadataApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create an API Component
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid component is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will create.
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public createComponent(request: CreateComponentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateComponentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Create an API Contract for a particular feature
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid contract is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will create a Contract
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public createContract(request: CreateContractRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateContractResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/contracts'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Create an API custom type for a particular feature
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid component feature is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will create a Custom Type
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public createCustomType(request: CreateCustomTypeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateCustomTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/types'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Create an API Feature for a particular feature
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid component feature is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will create a Feature
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public createFeature(request: CreateFeatureRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateFeatureResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Create the full file structure for a Component
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid component is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, 200 code will be returned
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public createFullFileComponent(request: CreateFullFileComponentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/create-full-file-component'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes an API component based on the component cd provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid API component cd is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If an API component exists with the cd provided, then it will be deleted.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public deleteComponent(
							   pathParams: DeleteComponentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Deletes a Contract based on the input path identifiers provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid Contract identifiers are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a Contract exists with the input identifiers, then it will be deleted.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public deleteContract(
							   pathParams: DeleteContractPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/features/{featureId}/contracts/{serviceName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Deletes a Custom Type based on the identifiers provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid Custom Type identifiers are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a Custom Type exists with the identifiers provided, then it will be deleted.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public deleteCustomType(
							   pathParams: DeleteCustomTypePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/features/{featureId}/types/{typeName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Deletes an API Feature based on the feature id provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid feature id is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a Feature exists with the feature id provided, then it will be deleted.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public deleteFeature(
							   pathParams: DeleteFeaturePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/{featureId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Returns an API component based on the component cd provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid API component cd is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If an API component exists with the component cd provided, then it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getComponent(
							   pathParams: GetComponentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetComponentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns an API contract based on the identifiers provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid API contract identifiers are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If an API contract exists with the identifiers provided, then it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getContract(
							   pathParams: GetContractPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetContractResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/features/{featureId}/contracts/{serviceName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns an API feature based on the id provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid API feature id is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If an API feature exists with the name provided, then it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getFeatureById(
							   pathParams: GetFeatureByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetFeatureByIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/{featureId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a type by feature and type name provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid feature id and type name is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If an API component exists with the name provided, then it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getTypeByFeatureIdAndName(
							   pathParams: GetTypeByFeatureIdAndNamePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetTypeByFeatureIdAndNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/{featureId}/types/{typeName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all of the API components that exist.
	* <br/>
	* <br/>Pre-condition:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the request is successful, a list of all API components will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listComponents(
							   queryParams: ListComponentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListComponentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all of the Contracts that exist by Component Cd.
	* <br/>
	* <br/>Pre-condition:
	* <br/>Component Cd is supplied and valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the request is successful, a list of all Contracts by Component Cd will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listContractsByComponentCd(
							   pathParams: ListContractsByComponentCdPath,
							   queryParams: ListContractsByComponentCdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListContractsByComponentCdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/contracts'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all of the Contracts that exist by Feature.
	* <br/>
	* <br/>Pre-condition:
	* <br/>Feature Id is supplied and valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the request is successful, a list of all Contracts by Feature id will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listContractsByFeatureId(
							   pathParams: ListContractsByFeatureIdPath,
							   queryParams: ListContractsByFeatureIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListContractsByFeatureIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/{featureId}/contracts'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all of the API Enums that exist.
	* <br/>Pre-condition:  None
	* <br/>Post-conditions:
	* <br/>1. If the request is successful, a list of all API Enums will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listEnums(
							   queryParams: ListEnumsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEnumsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/enums'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns list of Exadata user information by email address.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. None.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If user information exists with the email address provided, then it will be returned.
	* <br/>2. Otherwise, an appropriate error message will be returned.
	*/
	public listExadataUserByEmailAddress(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExadataUserByEmailAddressResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exadata'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all of the Features that exist by Component.
	* <br/>
	* <br/>Pre-condition:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the request is successful, a list of all Features by Component id will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listFeaturesByComponentCd(
							   pathParams: ListFeaturesByComponentCdPath,
							   queryParams: ListFeaturesByComponentCdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListFeaturesByComponentCdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/features'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all of the Types that exist by Component.
	* <br/>
	* <br/>Pre-condition:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the request is successful, a list of all Types by Component Cd will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listTypesByComponentCd(
							   pathParams: ListTypesByComponentCdPath,
							   queryParams: ListTypesByComponentCdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListTypesByComponentCdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/types'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists all of the Types that exist by Feature.
	* <br/>
	* <br/>Pre-condition:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the request is successful, a list of all Types by Feature id will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listTypesByFeatureId(
							   pathParams: ListTypesByFeatureIdPath,
							   queryParams: ListTypesByFeatureIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListTypesByFeatureIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/{featureId}/types'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates the status of the submitted Feature and any related Types or Contracts back to Saved status.  The featureId is required as input.
	* <br/>Pre-condition:
	* <br/>1. A valid component featureId is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If sucessful, the API operation will upate the Feature status and any contract or type within that Feature to Saved status.  Revert the Submitted Feature for updating the PR.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public revertSubmittedFeature(request: RevertSubmittedFeatureRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RevertSubmittedFeatureResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/revert'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Submit an API Feature for processing by service governance team
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid component feature is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will submit a Feature
	* <br/>				- change status of feature to Pending
	* <br/>				- convert any contracts in the feature to xml files
	* <br/>				- convert any types in the feature to xsd file
	* <br/>				- create a branch on the component contract Github project with the converted artifacts
	* <br/>				- create a PR for the new Github branch
	* <br/>				- create Jira ticket in LISG jira with a reference to the Github PR
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public submitFeature(request: SubmitFeatureRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SubmitFeatureResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/submit'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates an API component based on the component payload provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid component payload is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, the API component will be updated.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public updateComponent(request: UpdateComponentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateComponentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Update an API Contract for a particular feature
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid contract is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will update a Contract
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public updateContract(request: UpdateContractRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateContractResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/contracts'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Update an API custom type for a particular feature
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid type is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will update a Custom Type
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public updateCustomType(request: UpdateCustomTypeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateCustomTypeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/types'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Update an API Feature for a particular feature
	* <br/>
	* <br/>			Pre-condition:
	* <br/>			1. A valid component feature is provided.
	* <br/>			
	* <br/>			Post-conditions:
	* <br/>			1. If successful, the API component will update a Feature
	* <br/>			2. Otherwise an appropriate error message will be returned.
	* <br/>		
	*/
	public updateFeature(request: UpdateFeatureRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateFeatureResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation updates the status of the Feature provided and any contracts or types that art of that Feature as implemented.  It updates the existing contracts or types that were mdified in that Feature to a status of Archived so that there is only one implemented version of the same contract/type at any time.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid component featureId is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If sucessful, the API operation will upate the Feature status and any contract or type within that Feature to implemented.  Any existing contract or type of the same name that was previously implemented will be updated to archive status.
	* <br/>2. Otherwise an appropriate error message will returned.
	*/
	public updateFeatureAsImplemented(request: UpdateFeatureAsImplementedRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateFeatureAsImplementedResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/implemented'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Update Exadata user information in a database.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid email address, database name and Exadata user information is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then Exadata user information is updated in the database.
	* <br/>2. Otherwise, an appropriate error message will be returned.
	*/
	public updateExadataUser(request: UpdateExadataUserRqst,
							   pathParams: UpdateExadataUserPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateExadataUserResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/exadata/databases/{databaseName}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ApiMetadataApiService.ApiMetadataApiEndpoint);
	}
}
